module.exports = [{
      plugin: require('../node_modules/@eveema/gatsby-theme-core/gatsby-browser.js'),
      options: {"plugins":[],"api":"https://exm.eveema.com/api/projects/dietz/events/_/sitedata","key":"LyDZEEkAmAmGNg2D","apiAttendees":"https://exm.eveema.com/api/projects/dietz/events/"},
    },{
      plugin: require('../plugins/gatsby-plugin-top-layout/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"FMEA Forum","short_name":"FMEA-Forum","start_url":"/","background_color":"#f3f2f0","theme_color":"#453D48","display":"standalone","icon":"src/images/icon.png","crossOrigin":"use-credentials","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","include_favicon":true,"cacheDigest":"277987558b6e15c47a1eb2e98df45f8a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
