import { createTheme } from "@mui/material/styles";

const primary = "#c72e5a";
const secondary = "#453D48";

const theme = createTheme({
  palette: {
    type: "light",
    primary: {
      main: primary,
    },
    secondary: {
      main: secondary,
    },
    text: {
      primary: "#333333",
    },
  },
  color: {
    white: "#fff",
    black: "#000",
    gray: {
      100: "#f3f2f0",
      200: "#E1DFDF",
      300: "rgba(51, 51, 51,.5)",
      400: "#333333",
    },
    orange: "#FA6F14",
    green: "#44b716",
    yellow: "#F0C81A",
    purple: "#3E4A7C",
  },
  typography: {
    fontFamily: "Lato",
    h1: {
      fontWeight: 700,
      fontSize: 32,
    },
    h2: {
      fontWeight: 700,
      fontSize: 24,
    },
    subtitle1: {
      color: primary,
      fontStyle: "italic",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1680,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        maxWidthMd: {
          maxWidth: 960,
        },
        maxWidthLg: {
          maxWidth: 1280,
        },
        maxWidthXl: {
          maxWidth: 1680,
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          boxShadow: "none",
          borderRadius: "4px",
          fontWeight: 700,
          transition: "all .2s ease-in-out",
          color: "#fff",
          "&:hover": {
            boxShadow: "none",
            transform: "scale(1.1)",
          },
        },
        contained: {
          "&:hover": {
            background: primary,
          },
        },
        outlined: {
          "&:hover": {
            background: "rgba(255, 255, 255, 0)",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          transition: "all .2s ease-in-out",
          "&:hover": {
            background: "rgba(255, 255, 255, 0)",
            boxShadow: "none",
            transform: "scale(1.1)",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          "& img": {
            objectPosition: "top",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          padding: "12px 24px",
          fontSize: 18,
        },
        icon: {
          fontSize: 32,
        },
      },
    },
  },
});

export default theme;
